import React from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, Spacing, Text } from '@reservamos/elements';

function HourTag({ icon, label, hasBorder, bgWhite, bgColor }) {
  return (
    <Box
      alphaBg={bgWhite ? '' : 'XS'}
      borderRadius="L"
      paddingHorizontal="S"
      paddingVertical="XXS"
      paddingHorizontalMobile="XXS"
      bgColor={bgWhite ? 'white' : bgColor || 'grayMedium'}
      borderColor={hasBorder ? 'grayBorder' : ''}
      border={hasBorder ? 'all' : ''}
    >
      <Spacing alignItems="center" size="XS">
        <Icon type={icon} color={hasBorder ? 'accent' : 'grayStrong'} size="S" />
        <Text color="grayStrong" size="S" xsMobileSize="XXS">
          {label}
        </Text>
      </Spacing>
    </Box>
  );
}

HourTag.propTypes = {
  icon: PropTypes.oneOf(['Morning', 'Afternoon', 'Night', 'earlyMorning', 'Clock']),
  label: PropTypes.string.isRequired,
  hasBorder: PropTypes.bool,
  bgWhite: PropTypes.bool,
  bgColor: PropTypes.string,
};

HourTag.defaultProps = {
  icon: 'Afternoon',
  hasBorder: false,
  bgWhite: false,
  bgColor: '',
};

export default HourTag;
