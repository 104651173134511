import { connect } from 'react-redux';
import { submit as submitForm, formValueSelector } from 'redux-form';
import { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import { setError, clearError } from '@/actions';
import {
  selectPaymentOption,
  toggleWantsInsurance,
  resetInstallmentsPlan,
  updatePurchaseFields,
  resetPaymentCardError,
  selectPaymentMethod,
  toggleWantsCarbonOffset,
  saveBulkPassengers,
  setPassengers,
  fetchYunoData,
} from '@/actions/purchase';
import { getWalletTypeForPurchase } from 'utils/loyalty';
import {
  cardPaymentAttempt,
  paymentAttempt,
  transferPaymentAttempt,
  thirdPartyPaymentAttempt,
  storePaymentAttempt,
  efectyPaymentAttempt,
  evertecPaymentAttempt,
  clearOTP,
  generateOTP,
  confirmEvertecPayment,
  yunoPaymentAttempt,
} from '@/actions/payment';
import { fetchPaymentCards } from '@/actions/cards';
import availablePaymentEngines from 'constants/PaymentEngine';
import Checkout from './Checkout';
import { getBrand2ProcessorMap } from '../../../utils/documentMaps';

const mapStateToProps = (state) => {
  const {
    purchase,
    payment,
    siemprePlus,
    costapass,
    cards,
    whitelabelConfig: { features },
  } = state;

  const VALIDATE_PURCHASER = features.PURCHASER_FORM_ON === 'CHECKOUT';
  const CHECK_DOCUMENT = features.IDENTIFICATION_DOCUMENT_FOR_PURCHASER;

  const keys = [
    'purchaserFirstName',
    'purchaserLastName',
    features.SHOW_PURCHASER_SECOND_LAST_NAME_FIELD && 'purchaserSecondLastName',
    'email',
    'phoneCountry',
    'phone',
    'phoneCode',
    CHECK_DOCUMENT && 'documentType',
    CHECK_DOCUMENT && 'documentId',
    'taxpayerId',
    'taxpayerLegalName',
    'taxpayerAddress',
  ].filter(Boolean);

  const purchaseJs = purchase.toJS();
  const cardsJs = cards.toJS();
  const userData = siemprePlus.toJS().user;

  const cardList = cards.get('cards');
  const selectedCardReference = cards.get('selectedCard');

  const selectedCard = cardList.find(
    (card) => card.recurringDetail.recurringDetailReference === selectedCardReference,
  );

  const cardSelector = formValueSelector('card');
  const purchaserFields = formValueSelector('purchaser')(state, ...keys);
  const legalTerms = formValueSelector('purchaser')(state, 'legalTerms');
  const requiresTermsValidation =
    features.POLICIES_DISCLAIMER_LOCATION === 'CHECKOUT' && features.SHOW_LEGAL_TERMS_CHECKBOX;

  if (purchaserFields.phone) {
    purchaserFields.phone = purchaserFields.phone.replace(/ /g, '');
  }

  const purchaserUpdated = keys.every((key) => {
    if (purchaserFields[key] === undefined) return true;
    // Avoid checking for phoneCountry if this field is not present on backend

    if ((key === 'phoneCountry' || key === 'phoneCode') && !features.PHONE_COUNTRY_SAVED_BACKEND)
      return true;
    if (key === 'email' && !features.SHOW_PURCHASER_EMAIL_FIELD) return true;
    if (key === 'phone') {
      const phoneCode =
        purchaserFields.phoneCountry && getCountryCallingCode(purchaserFields.phoneCountry);
      const parsedNumber = parsePhoneNumber(`+${phoneCode}${purchaserFields.phone}`);
      const phone = parsedNumber && parsedNumber.nationalNumber;
      return purchaseJs[key] === phone;
    }

    if (['taxpayerLegalName', 'taxpayerAddress'].includes(key)) {
      return purchaseJs[key] === undefined || purchaseJs[key] === purchaserFields[key];
    }

    return purchaseJs[key] === purchaserFields[key];
  });

  const isPurchaserValid = purchaserUpdated && (!requiresTermsValidation || legalTerms);
  const { availableWallets = [] } = purchaseJs;
  const selectedLoyaltyProgram = purchaseJs.walletType || getWalletTypeForPurchase();
  const isValidWalletType =
    selectedLoyaltyProgram && availableWallets.includes(selectedLoyaltyProgram);

  return {
    user: userData,
    isLogged: siemprePlus.get('logged') || costapass.get('logged'),
    cardsUpdated: cardsJs.isUpdated,
    cardsLoading: cardsJs.isLoading,
    selectedCard,
    purchase: purchaseJs,
    isUpdating: purchase.get('isUpdating'),
    isExchange: purchase.get('isExchange'),
    fetchingPayment: payment.get('isFetching') || false,
    paymentId: payment.get('id'),
    cardErrorOccured: payment.get('cardErrorOccured'),
    purchaserUpdated: VALIDATE_PURCHASER ? isPurchaserValid : true,
    purchaser: purchaserFields,
    cardValues: cardSelector(
      state,
      'holderName',
      'cardNumber',
      'cvv2',
      'expirationMonth',
      'expirationYear',
      'paymentPlan',
      'monthlySelectedPlan',
      'billingAddress',
      'cardBrand',
    ),
    insuranceMessage: purchase.getIn(['config', 'insuranceMessage']),
    insuranceAmount: purchase.getIn(['config', 'insuranceAmount']),
    insuranceAmountIsUnitPrice: purchase.getIn(['config', 'unitAmount']),
    selectedPlan: purchase.get('selectedPlan'),
    paymentEngine: purchase.get('paymentEngine'),
    monthlySelectedPlan: purchase.get('monthlySelectedPlan'),
    selectedPaymentMethod: purchase.get('selectedPaymentMethod'),

    /**
     * Carbon ancillary props
     */
    carbonOffset: purchase.getIn(['config', 'carbonOffset'])?.toJS(),
    loadingOTP: payment.get('loadingOTP'),
    showOTPModal: payment.get('showOTPModal'),
    otpConfirmed: payment.get('otpConfirmed'),
    otpTriggerPayment: payment.get('otpTriggerPayment'),
    selectedLoyaltyProgram: isValidWalletType ? selectedLoyaltyProgram : null,
    features,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchCardList: () => dispatch(fetchPaymentCards()),
  onToggleWantsInsurance: (token, wants) => dispatch(toggleWantsInsurance(token, wants)),
  onSubmitForm: (formName) => dispatch(submitForm(formName)),
  onFormFail: () =>
    dispatch(
      setError(null, 'check_the_fields_in_red', 'warning', false, null, null, null, 'fixed'),
    ),
  onNoCardSelected: () =>
    dispatch(setError(200, 'select_a_card', 'warning', false, null, null, 'fixed')),
  onPaymentAttempt: (token, type) => dispatch(paymentAttempt(token, type)),
  onThirdpartyPaymentAttempt: (token, type) => dispatch(thirdPartyPaymentAttempt(token, type)),
  onSelectPaymentOption: (option, method) => {
    dispatch(selectPaymentOption(option));
    dispatch(selectPaymentMethod(method || {}));
  },
  transferPaymentAttempt: (config) => dispatch(transferPaymentAttempt(config)),
  resetInstallmentsPlan: () => {
    dispatch(resetInstallmentsPlan());
  },
  storePaymentAttempt: (config) => dispatch(storePaymentAttempt(config)),
  transitionTo: (route) => ownProps.history.push(route),
  returnTo: (route) => ownProps.history.replace(route),
  setPurchaser: (token, fields) => dispatch(updatePurchaseFields(token, fields)),
  resetPaymentCardError: () => dispatch(resetPaymentCardError()),
  onEfectyPaymentAttempt: () => dispatch(efectyPaymentAttempt()),
  onEvertecPaymentAttempt: (paymentData) => dispatch(evertecPaymentAttempt(paymentData)),
  onConfirmEvertecPayment: () => dispatch(confirmEvertecPayment()),
  onEvertecPaymentFailure: () =>
    dispatch(setError(301, 'error_generating_payment', 'warning', false)),
  onYunoPaymentAttempt: (paymentData) => dispatch(yunoPaymentAttempt(paymentData)),
  fetchYunoData: (purchaseData) => dispatch(fetchYunoData(purchaseData)),
  clearError: () => dispatch(clearError()),
  /**
   * Carbon ancillary props
   */
  onToggleWantsCarbonOffset: (token, wants) => dispatch(toggleWantsCarbonOffset(token, wants)),
  clearOTP: () => dispatch(clearOTP()),
  generateOTP: (walletBalanceUsed) => dispatch(generateOTP(walletBalanceUsed)),
  dispatchCardPaymentAttempt: (token, cardValues, paymentInfo) =>
    dispatch(cardPaymentAttempt(token, cardValues, paymentInfo)),
  saveBulkPassengers: (token, passengerPayload) =>
    dispatch(saveBulkPassengers(token, passengerPayload)),
  savePassengers: (token, passengers) => dispatch(setPassengers(passengers, token)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onCardPaymentAttempt: (token, cardValues, paymentInfo) => {
    const { dispatchCardPaymentAttempt } = dispatchProps;
    const { monthlySelectedPlan } = stateProps.purchase;
    const cardValueWithMonthlyPlan = {
      ...cardValues,
      monthlySelectedPlan,
    };
    dispatchCardPaymentAttempt(token, cardValueWithMonthlyPlan, paymentInfo);
  },
  onTransferPaymentAttempt: ({ bank, personType, isLegacyPix }) => {
    const {
      description,
      documentId,
      documentType,
      email,
      selectedInstallmentsPlan,
      token,
      total,
      selectedPaymentMethod,
    } = stateProps.purchase;
    const { transferPaymentAttempt } = dispatchProps;

    const validatedTotal =
      selectedInstallmentsPlan && selectedInstallmentsPlan.months > 1
        ? selectedInstallmentsPlan.total
        : total;

    const brand2ProcessorMap = getBrand2ProcessorMap();
    transferPaymentAttempt({
      bankId: bank,
      personType,
      documentType: brand2ProcessorMap[documentType],
      documentNumber: documentId,
      email,
      paymentDescription: description,
      purchaseToken: token,
      total: validatedTotal,
      engine: isLegacyPix ? availablePaymentEngines.mercadoPago : selectedPaymentMethod.engine,
      provider: isLegacyPix ? 'pix' : selectedPaymentMethod.provider,
    });
  },
  onSetPurchaser: () => {
    const { setPurchaser } = dispatchProps;
    const { purchase, purchaser } = stateProps;

    return setPurchaser(purchase.token, purchaser);
  },
  onStorePaymentAttempt: () => {
    const {
      token,
      purchaserFirstName,
      purchaserLastName,
      documentId,
      documentType,
      selectedInstallmentsPlan,
      description,
      total,
    } = stateProps.purchase;
    const { storePaymentAttempt } = dispatchProps;

    const validatedTotal =
      selectedInstallmentsPlan && selectedInstallmentsPlan.months > 1
        ? selectedInstallmentsPlan.total
        : total;

    const brand2ProcessorMap = getBrand2ProcessorMap();
    storePaymentAttempt({
      token,
      documentType: brand2ProcessorMap[documentType],
      name: purchaserFirstName,
      lastName: purchaserLastName,
      identification: documentId,
      totalAmount: validatedTotal,
      currency: 'COP',
      description,
    });
  },

  // TODO: Review if this work for saved cards
  onAdyenPaymentAttempt: (selectedCard) => {
    const { token: purchaseToken, total, monthlySelectedPlan } = stateProps.purchase;
    const { currency } = stateProps.env;
    const { recurringDetail } = selectedCard;

    // This object don't have adyenToken because is not a new card
    // and only need the reference to make the payment
    const paymentInfo = {
      amount: total,
      reference: recurringDetail.recurringDetailReference,
      currency,
    };

    const cardValues = {
      holderName: recurringDetail.card.holderName,
      cardNumber: `${recurringDetail.additionalData.cardBin}xxxxxx${recurringDetail.card.number}`,
      cardBrand: recurringDetail.variant,
      monthlySelectedPlan,
    };

    cardPaymentAttempt(purchaseToken, cardValues, paymentInfo);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Checkout);
