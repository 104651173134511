import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { checkoutFunnelStep } from 'utils/Reserbus';
import getCardBrand from 'utils/cards';
import formErrorsTracker from 'metrics/user-analytics/formErrorsTracker';
import Loading from 'components/Loading';
import CheckoutNavigation from 'components/purchase/CheckoutNavigation';
import DiscountCode from 'components/purchase/DiscountCode';
import PaymentOptions from 'components/purchase/PaymentOptions';
import PaymentButton from 'components/PaymentButton';
import { shouldUseAdyen } from 'utils/adyen';
import OneSignal from 'react-onesignal';
import { identifyUser, trackEvent } from 'user-analytics';
import { Spacing, Text, Visibility } from '@reservamos/elements';
import { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import AgreementCheckboxFields from 'components/AgreementCheckboxFields';
import wayIsOpenTicket, { isHybridTrip } from '../../../utils/wayIsOpenTicket';
import { MercadoPagoTDCContext } from '../../mercadoPago';
import PriceChangedModal from '../PriceChangedModal';
import OpenTicketMessage from '../OpenTicketMessage';
import CheckoutWallet from '../CheckoutWallet';
import SmallNotice from '../../../ui/atoms/SmallNotice';
import availablePaymentEngines from '../../../constants/PaymentEngine';
import InfoText from '../../../ui/atoms/InfoText';
import ReturnCTA from '../Purchase/ReturnCTA';
import AncillariesSwitch from '../../../ui/atoms/AncillariesSwitch';
import CostaPassSlider from '../../CostaPass/CostaPassSlider';
import PassengerPolicies from '../PassengerPolicies';
import DotersSlider from '../../Doters/DotersSlider/DotersSlider';
import SSOAuthDialog from '../../sso/SSOAuthDialog';
import DotersBanner from '../../../ui/atoms/DotersBanner';
import adyen from '../../../../payments/core/engines/adyen';
import DotersModalOtp from '../../Doters/DotersModalOtp';
import DotersModalUsage from '../../Doters/DotersModalUsage/DotersModalUsage';
import BookingReview from '../../../ui/atoms/BookingReview';
import InsuranceAncillarySwitch from '../InsuranceAncillarySwitch';
import evertec from '../../../../payments/core/engines/evertec';
import PassengerSelectorBox from '../PassengerSelectorBox';

const propTypes = {
  purchase: PropTypes.shape({
    isUpdating: PropTypes.bool.isRequired,
    hasTickets: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    loaded: PropTypes.bool.isRequired,
    state: PropTypes.string.isRequired,
    departs: PropTypes.object.isRequired,
    returns: PropTypes.object.isRequired,
    availablePayments: PropTypes.array,
    roundTrip: PropTypes.bool,
    selectedInstallmentsPlan: PropTypes.shape({
      months: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      monthlyPayment: PropTypes.number.isRequired,
    }),
    passengers: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        phoneCode: PropTypes.string,
        phoneCountry: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    paymentEngine: PropTypes.string.isRequired,
    qualifiesForInsurance: PropTypes.bool.isRequired,
    selectedPaymentOption: PropTypes.string.isRequired,
    selectedPaymentMethod: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    updatingInsurance: PropTypes.bool.isRequired,
    applyingDiscount: PropTypes.bool.isRequired,
    wantsInsurance: PropTypes.bool.isRequired,
    openTicket: PropTypes.bool,
    isExchange: PropTypes.bool,
    email: PropTypes.string,
    paymentMethods: PropTypes.array,
    bulkTicket: PropTypes.bool,
    yuno: PropTypes.shape({
      checkoutSession: PropTypes.string,
      merchantOrderId: PropTypes.string,
      availableMethods: PropTypes.array,
    }),

    /**
     * Carbon ancillary props
     */
    wantsCarbonOffset: PropTypes.bool.isRequired,
    updatingCarbonOffset: PropTypes.bool.isRequired,
    updatingWallet: PropTypes.bool,
    walletPointsUsed: PropTypes.number,
    walletType: PropTypes.string,
    walletBalanceUsed: PropTypes.number,
    walletMinimumPointsToBeUsed: PropTypes.number,
    isUpdatingWalletType: PropTypes.bool,
    walletTotalPointsUsed: PropTypes.number,
    walletTotalBalanceUsed: PropTypes.number,
    fetchingTaxpayerId: PropTypes.bool,
  }),
  purchaser: PropTypes.shape({
    purchaserFirstName: PropTypes.string,
    purchaserLastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    phoneCountry: PropTypes.string,
    phoneCode: PropTypes.string,
  }),
  fetchingPayment: PropTypes.bool.isRequired,
  paymentId: PropTypes.number,
  cardValues: PropTypes.object.isRequired,
  fetchCardList: PropTypes.func.isRequired,
  onToggleWantsInsurance: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  onFormFail: PropTypes.func.isRequired,
  onCardPaymentAttempt: PropTypes.func.isRequired,
  onPaymentAttempt: PropTypes.func.isRequired,
  transitionTo: PropTypes.func.isRequired,
  returnTo: PropTypes.func.isRequired,
  onSelectPaymentOption: PropTypes.func.isRequired,
  resetInstallmentsPlan: PropTypes.func.isRequired,
  insuranceMessage: PropTypes.string,
  insuranceAmount: PropTypes.string,
  insuranceAmountIsUnitPrice: PropTypes.bool,
  onTransferPaymentAttempt: PropTypes.func.isRequired,
  onThirdpartyPaymentAttempt: PropTypes.func.isRequired,
  purchaserUpdated: PropTypes.bool.isRequired,
  onSetPurchaser: PropTypes.func.isRequired,
  onStorePaymentAttempt: PropTypes.func.isRequired,
  cardErrorOccured: PropTypes.bool,
  t: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isExchange: PropTypes.bool.isRequired,
  user: PropTypes.object,
  isLogged: PropTypes.bool.isRequired,
  cardsUpdated: PropTypes.bool,
  cardsLoading: PropTypes.bool,
  onAdyenPaymentAttempt: PropTypes.func.isRequired,
  selectedCard: PropTypes.object,
  monthlySelectedPlan: PropTypes.string.isRequired,
  resetPaymentCardError: PropTypes.func,
  features: PropTypes.object.isRequired,
  env: PropTypes.object.isRequired,
  paymentEngine: PropTypes.string,
  onEfectyPaymentAttempt: PropTypes.func.isRequired,
  onEvertecPaymentAttempt: PropTypes.func.isRequired,
  onConfirmEvertecPayment: PropTypes.func.isRequired,
  onEvertecPaymentFailure: PropTypes.func.isRequired,
  onYunoPaymentAttempt: PropTypes.func.isRequired,
  fetchYunoData: PropTypes.func.isRequired,
  selectedPaymentMethod: PropTypes.object.isRequired,
  clearError: PropTypes.func,
  /**
   * Carbon ancillary props
   */
  carbonOffset: PropTypes.object,
  onToggleWantsCarbonOffset: PropTypes.func.isRequired,
  onNoCardSelected: PropTypes.func,
  otpTriggerPayment: PropTypes.bool,
  showOTPModal: PropTypes.bool,
  loadingOTP: PropTypes.bool,
  clearOTP: PropTypes.func,
  generateOTP: PropTypes.func,
  otpConfirmed: PropTypes.bool,
  selectedLoyaltyProgram: PropTypes.string,
  saveBulkPassengers: PropTypes.func.isRequired,
};

/**
 * Checkout Component
 * Handles the display and interaction of the checkout process including payment options,
 * insurance selection, carbon offset option, and other ancillary services.
 * It manages state related to payment type, form submission, and OneSignal data for notifications.
 * The component also handles redirection based on user state and ticket availability,
 * OTP handling for Doters loyalty program, and rendering of various UI components
 * based on the features available and the user's choices.
 */
class Checkout extends Component {
  constructor(props) {
    super(props);

    const { availablePayments } = props.purchase;

    this.state = {
      paymentType: availablePayments[0] || 'credit_card',
      dotersUsageConfirmed: false,
      showDotersModal: false,
      isEvertecLoading: false,
      isYunoLoading: false,
    };

    this.setPaymentType = this.setPaymentType.bind(this);
    this.toggleWantsInsurance = this.toggleWantsInsurance.bind(this);
    this.handleFormErrors = this.handleFormErrors.bind(this);
    this.paymentButtonClick = this.paymentButtonClick.bind(this);
    this.cardPaymentAttempt = this.cardPaymentAttempt.bind(this);
    this.paymentAttempt = this.paymentAttempt.bind(this);
    this.onSubmitTransferForm = this.onSubmitTransferForm.bind(this);
    this.onSubmitPurchaser = this.onSubmitPurchaser.bind(this);
    this.toggleWantsCarbonOffset = this.toggleWantsCarbonOffset.bind(this);
    this.needsConfirmation = this.needsConfirmation.bind(this);
    this.handleDotersUsageModalButtonClick = this.handleDotersUsageModalButtonClick.bind(this);
    this.handleEvertecPayment = this.handleEvertecPayment.bind(this);
    this.handleYunoPayment = this.handleYunoPayment.bind(this);
  }

  componentDidMount() {
    const { resetPaymentCardError, clearOTP } = this.props;

    resetPaymentCardError();
    this.redirectIfAppropriate();
    this.getYunoAvailableMethods();
    clearOTP();
  }

  componentDidUpdate(prevProps) {
    const {
      transitionTo,
      fetchingPayment,
      purchase,
      paymentId,
      user,
      fetchCardList,
      cardsUpdated,
      cardsLoading,
      features,
      otpTriggerPayment,
    } = this.props;

    this.redirectIfAppropriate(prevProps);

    const { total, availablePayments } = purchase;
    if (
      (prevProps.purchase.selectedPaymentOption === 'reservamos_pay' ||
        prevProps.purchase.selectedPaymentOption === 'free_pay') &&
      total &&
      availablePayments.length
    ) {
      this.setPaymentType(availablePayments[0] || 'credit_card');
    }
    if (prevProps.fetchingPayment && !fetchingPayment) {
      // go to payment processor after fetching the payment
      transitionTo(`/payment/${purchase.token}/${paymentId}`);
    }

    if (features.ALLOW_TO_SAVE_CARDS && !cardsUpdated && !cardsLoading) {
      if (user) {
        fetchCardList();
      }
    }

    const purchaserFormInCheckoutStep = features.PURCHASER_FORM_ON === 'CHECKOUT';
    if (purchase.isUpdating && purchaserFormInCheckoutStep) {
      window.scrollTo({ top: 0 });
    }

    // If the otp has already been validated, the payment is triggered
    if (features.USE_OTP && !fetchingPayment && otpTriggerPayment && !prevProps.otpTriggerPayment) {
      this.paymentButtonClick();
    }
  }

  componentWillUnmount() {
    const { resetInstallmentsPlan } = this.props;
    resetInstallmentsPlan();
  }

  handleFormErrors(fields) {
    const { paymentType } = this.state;
    // prettier-ignore
    const {
      onFormFail,
      purchase: { departs, returns },
      cardValues: { cardNumber },
    } = this.props;

    const paymentInfo = {
      paymentType,
    };

    if (cardNumber) {
      paymentInfo.cardBrand = getCardBrand(cardNumber);
    }

    if (fields) {
      formErrorsTracker(checkoutFunnelStep(departs, returns), fields, paymentInfo);
      onFormFail();
    }
  }

  handleDotersUsageModalButtonClick(status) {
    this.setState({ showDotersModal: false });
    trackEvent('Doters Points Confirmed', { accepted: status });
    if (status) {
      this.setState({ dotersUsageConfirmed: status }, () => this.paymentButtonClick());
    }
  }

  async handleEvertecPayment() {
    this.setState({ isEvertecLoading: true });
    const {
      env: {
        api: { purchaseUrl },
        brand,
        currency,
      },
      purchase: {
        token,
        total: amount,
        selectedPaymentMethod: { type, provider },
      },
      purchaser,
      onEvertecPaymentAttempt,
      onConfirmEvertecPayment,
      onEvertecPaymentFailure,
    } = this.props;

    const description = 'ecommerce & apps for bus tickets';
    const returnUrl = `${window.location.origin}/purchase/${token}/complete`;
    const cancelUrl = `${window.location.origin}/payment/${token}/canceled`;

    const resp = await evertec.createSession({
      purchaseUrl,
      brand,
      purchaseId: token,
      description,
      currency,
      amount,
      returnUrl,
      cancelUrl,
      paymentType: type,
      purchaser,
    });

    if (resp.status === 'failure') {
      this.setState({ isEvertecLoading: false });
      return onEvertecPaymentFailure();
    }

    const { process_url: processUrl, request_id: requestId } = resp;

    try {
      await onEvertecPaymentAttempt({ purchaseToken: token, type, provider, requestId });
    } catch {
      return;
    } finally {
      this.setState({ isEvertecLoading: false });
    }

    if (type === 'transfer') {
      window.location.href = processUrl;
    } else {
      await evertec.initialize(processUrl);

      const evertecInstance = evertec.getInstance();

      if (evertecInstance) {
        evertecInstance.on('response', (resp) => {
          const { status } = resp.status;
          if (status === 'REJECTED') {
            window.location.href = `/payment/${token}/canceled`;
          } else {
            onConfirmEvertecPayment();
          }
        });
      } else {

      }
    }
  }

  async handleYunoPayment() {
    this.setState({ isYunoLoading: true });
    const {
      purchase: {
        selectedPaymentMethod,
        token: purchaseToken,
        yuno: { checkoutSession, merchantOrderId },
      },
      onYunoPaymentAttempt,
    } = this.props;
    try {
      await onYunoPaymentAttempt({
        checkoutSession,
        merchantOrderId,
        selectedPaymentMethod,
        purchaseToken,
      });
    } catch (error) {
      throw new Error(`Failed to process Yuno payment: ${error.message}`);
    } finally {
      this.setState({ isYunoLoading: false });
    }
  }

  onSubmitTransferForm(fields) {
    const { onTransferPaymentAttempt } = this.props;
    onTransferPaymentAttempt(fields);
  }

  onSubmitPurchaser() {
    const { onSetPurchaser } = this.props;
    onSetPurchaser().then(() => {
      this.paymentButtonClick();
    });
  }

  async getYunoAvailableMethods() {
    const {
      env: {
        api: { purchaseUrl },
        yuno,
      },
      purchase: { token: purchaseToken, total: amount },
      fetchYunoData,
    } = this.props;
    if (!yuno?.enabled) return null;

    try {
      await fetchYunoData({ purchaseUrl, purchaseToken, amount });
    } catch (error) {
      throw new Error(`Error in getYunoAvailableMethods: ${error}`);
    }
  }

  setPaymentType(paymentType, paymentMethod) {
    if (paymentMethod?.provider === 'yuno') {
      paymentMethod = {
        provider: paymentMethod.provider,
        engine: paymentMethod.engine,
        type: paymentMethod.type,
      };
    }
    const { onSelectPaymentOption } = this.props;

    this.setState({ paymentType });
    onSelectPaymentOption(paymentType, paymentMethod);
  }

  setOneSignalData() {
    const { user, purchaser, purchase, env } = this.props;
    const hasOneSignalEnabled = env.oneSignal.enabled;
    const userEmail = purchaser.email || purchase.email;
    const phoneNumber = parsePhoneNumber(
      `+${getCountryCallingCode(purchaser.phoneCountry)}${purchaser.phone}`,
    );
    if (hasOneSignalEnabled) {
      OneSignal.setSMSNumber(`${phoneNumber.number}`);
      OneSignal.setExternalUserId(phoneNumber.nationalNumber);
      OneSignal.setEmail(userEmail);
      OneSignal.sendTags({ purchase_token: purchase.token });
    }
    identifyUser(phoneNumber.nationalNumber, {
      '$first_name': purchaser.purchaserFirstName,
      '$last_name': purchaser.purchaserLastName,
      '$name': `${purchaser.purchaserFirstName} ${purchaser.purchaserLastName}`,
      '$email': userEmail,
      '$phone': phoneNumber.nationalNumber,
      'User Type': user ? 'Registered' : 'Anonymous',
      '$onesignal_user_id': phoneNumber.nationalNumber,
      ...(user ? { $membership_id: user.spCard || user.dotersId } : {}),
    });
  }

  getWalletPointsText() {
    const { t, purchase, env } = this.props;
    const { travelpassLoyaltyConfig } = env;
    const prefix = travelpassLoyaltyConfig?.prefix || 'costapass';
    const { walletType } = purchase;
    const types = {
      doters: 'payment:label.pay_with_doter_points',
      costapass: 'payment:label.pay_with',
      travelpass: 'payment:label.pay_with',
      siempreplus: 'payment:label.pay_with_kilometres',
    };
    return t(types[walletType], { context: prefix });
  }

  toggleWantsCarbonOffset(event) {
    const {
      purchase: { token },
      onToggleWantsCarbonOffset,
    } = this.props;

    onToggleWantsCarbonOffset(token, event.target.checked);
  }

  toggleWantsInsurance(event) {
    const {
      purchase: { token },
      onToggleWantsInsurance,
    } = this.props;

    onToggleWantsInsurance(token, event.target.checked);
  }

  redirectIfAppropriate(prevProps) {
    const { purchase, returnTo, isLogged } = this.props;
    const { hasTickets, passengers, token, bulkTicket: isBulkTicket } = purchase;
    const hasPassengers = passengers.length > 0;

    if (isBulkTicket) return;

    // Use previous props if given
    if (prevProps) {
      // If state has changed from not logged to logged
      // then we redirect back to passengers page to update tickets
      if (!prevProps.isLogged && isLogged) {
        returnTo(`/purchase/${token}/passengers`);
        return;
      }
    }

    if (!(hasTickets && hasPassengers)) {
      returnTo(`/purchase/${token}/passengers`);
    }
  }

  needsConfirmation() {
    const { purchase, features, otpConfirmed, generateOTP, user } = this.props;
    const { walletPointsUsed, walletMinimumPointsToBeUsed, walletType } = purchase;
    const { dotersUsageConfirmed } = this.state;
    const hasUsedPoints = walletPointsUsed || walletMinimumPointsToBeUsed;

    // If either OTP or confirmation modal has been accepted, return false
    if (otpConfirmed || dotersUsageConfirmed) return false;

    if (walletType === 'doters' && user?.email && hasUsedPoints) {
      if (features.USE_OTP) {
        // Confirmation happens in OTP modal
        generateOTP(parseFloat(walletPointsUsed || walletMinimumPointsToBeUsed));
        return true;
      }

      // Confirmation happens in prompt modal
      this.setState({ showDotersModal: true });
      return true;
    }
    return false;
  }

  paymentButtonClick() {
    const {
      onSubmitForm,
      purchaserUpdated,
      onStorePaymentAttempt,
      purchase: { isUpdating, selectedPaymentOption },
      onAdyenPaymentAttempt,
      fetchingPayment,
      selectedCard,
      paymentEngine,
      features,
      env,
      onEfectyPaymentAttempt,
      selectedPaymentMethod,
      clearError,
      onNoCardSelected,
      saveBulkPassengers,
      onFormFail,
      purchaser,
      purchase,
    } = this.props;
    const { token, bulkTicket, passengers } = purchase;

    if (isUpdating || fetchingPayment) return;

    const { submitForm: submitMercadoPagoTDCForm } = this.context;
    const mercadoPagoIsEnabled = env.mercadoPago.enabled;
    const shouldUseMercadoPago =
      mercadoPagoIsEnabled && paymentEngine === availablePaymentEngines.mercadoPago;
    const shouldUseEvertec = selectedPaymentMethod.engine === 'evertec';
    const shouldUseYuno = selectedPaymentMethod.provider === 'yuno';

    const showPurchaserForm = features.PURCHASER_FORM_ON === 'CHECKOUT';
    const { provider } = selectedPaymentMethod || {};

    if (this.needsConfirmation()) return;

    // If it's a bulk ticket but purchaser is already updated, or if it's not a bulk ticket
    if (showPurchaserForm && !purchaserUpdated) {
      onSubmitForm('purchaser');
      return;
    }

    /**
     * Processes payment after validating all necessary conditions.
     * Handles different payment methods like Adyen, MercadoPago, Evertec, etc.
     */
    const processPayment = () => {
      clearError();

      if (showPurchaserForm) {
        this.setOneSignalData();
      }

      // when availablePayments comes as an objects array, selectedPaymentOption will be an object too
      // we would know what action to trigger using the selectedPaymentOption.type property
      // we would also have available the engine and provider to pass to the action
      // if (typeof selectedPaymentOption === 'object') {
      if (selectedPaymentOption === 'credit_card') {
        if (shouldUseAdyen(paymentEngine)) {
          if (selectedCard) {
            onAdyenPaymentAttempt(selectedCard);
          } else {
            const adyenInstance = adyen.getAdyenInstance();
            if (!adyenInstance) {
              onNoCardSelected();
              return;
            }
            adyenInstance.submit();
          }
        } else if (shouldUseMercadoPago) {
          submitMercadoPagoTDCForm();
        } else if (shouldUseEvertec) {
          this.handleEvertecPayment();
        } else {
          onSubmitForm('card');
        }
      } else if (shouldUseYuno) {
        this.handleYunoPayment();
      } else if (selectedPaymentOption === 'transfer') {
        if (provider === 'pix' || provider === 'nequi') {
          this.onSubmitTransferForm({});
        } else if (shouldUseEvertec) {
          this.handleEvertecPayment();
        } else {
          onSubmitForm('transfer');
        }
      } else if (selectedPaymentOption === 'store') {
        onStorePaymentAttempt();
      } else if (selectedPaymentOption === 'pix') {
        this.onSubmitTransferForm({ isLegacyPix: true });
      } else if (selectedPaymentOption === 'efecty') {
        onEfectyPaymentAttempt();
      } else {
        this.paymentAttempt();
      }
    };

    // Special handling for bulk tickets
    if (bulkTicket) {
      const { email, phone, phoneCode, phoneCountry, purchaserFirstName, purchaserLastName } =
        purchaser;

      if (passengers.length === 0) {
        onFormFail();
        return;
      }

      // Format passengers with purchaser data
      const passengersPayload = passengers.map((passenger) => ({
        ...passenger,
        lastName: purchaserLastName,
        firstName: purchaserFirstName,
        email,
        phone,
        phoneCode,
        phoneCountry,
        name: `${purchaserFirstName} ${purchaserLastName}`,
      }));

      // First update the purchaser
      saveBulkPassengers(token, passengersPayload)
        .then(() => {
          // Once both operations are complete, proceed with payment
          processPayment();
        })
        .catch((error) => {

          onFormFail();
        });
      return;
    }

    // If all validations pass, process the payment
    processPayment();
  }

  // This function is passed to PaymentOptions component
  // to use in the CardForm component when don't use Adyen or MercadoPago
  // like CyberSource for make the payment attempt
  cardPaymentAttempt() {
    const {
      purchase: { token, total: amount },
      onCardPaymentAttempt,
      cardValues,
      env: { currency },
    } = this.props;

    const paymentInfo = {
      amount,
      currency,
    };

    onCardPaymentAttempt(token, cardValues, paymentInfo);
  }

  paymentAttempt() {
    const {
      purchase,
      onPaymentAttempt,
      onThirdpartyPaymentAttempt,
      purchase: { selectedPaymentOption, selectedPaymentMethod },
    } = this.props;
    if (selectedPaymentOption === 'third_party') {
      onThirdpartyPaymentAttempt(purchase.token, selectedPaymentMethod);
    } else {
      onPaymentAttempt(purchase.token, selectedPaymentOption);
    }
  }

  render() {
    const {
      fetchingPayment,
      purchase: {
        isUpdating,
        isExchange,
        total,
        wantsInsurance,
        availablePayments,
        qualifiesForInsurance,
        updatingInsurance,
        applyingDiscount,
        selectedPaymentOption,
        selectedInstallmentsPlan,
        openTicket,
        paymentMethods,
        departs,
        returns,
        updatingCarbonOffset,
        wantsCarbonOffset,
        updatingWallet,
        walletType,
        walletPointsUsed,
        walletTotalPointsUsed,
        walletBalanceUsed,
        walletMinimumPointsToBeUsed,
        walletTotalBalanceUsed,
        isUpdatingWalletType,
        passengers,
        bulkTicket,
        fetchingTaxpayerId,
      },
      insuranceMessage,
      insuranceAmount,
      insuranceAmountIsUnitPrice,
      cardErrorOccured,
      t,
      user,
      monthlySelectedPlan,
      features,
      env,
      carbonOffset,
      showOTPModal,
      loadingOTP,
      selectedLoyaltyProgram,
    } = this.props;

    const { showDotersModal, isEvertecLoading, isYunoLoading } = this.state;

    const { paymentType: localPaymentType } = this.state;
    const paymentType = selectedPaymentOption || localPaymentType;

    const showInstallments = paymentType === 'credit_card' && monthlySelectedPlan > 1;
    let selectedPaymentTotal = total;

    if (showInstallments) {
      selectedPaymentTotal = selectedInstallmentsPlan.total;
    }

    const isLoading = isUpdating || fetchingPayment || isUpdatingWalletType;

    const installmentNotice = <SmallNotice selectedInstallmentsPlan={selectedInstallmentsPlan} />;

    const shouldRenderLoading = (isLoading && !env.adyen.enabled) || (isLoading && isExchange);

    const purchaserFormInCheckoutStep = features.PURCHASER_FORM_ON === 'CHECKOUT';

    // Checking if one of the trips is open ticket to notify that the insurance is not available for open ticket
    const isHybrid = isHybridTrip(departs, returns);
    const showMessageNotInsuranceOpenTicket = isHybrid && !features.OPENT_TICKET_INSURANCE_ENABLED;
    const enablesCarbon = carbonOffset && carbonOffset.available;
    const enablesInsurance =
      ((isExchange && features.CAN_ADD_EXCHANGE_INSURANCE) || !isExchange) &&
      qualifiesForInsurance &&
      !features.PASSENGERS_INSURANCE_ENABLED;
    const insuranceTitle = t('insurance:Insurance Checkbox Title', { context: 'coverage' });

    const buttonLoading =
      isLoading ||
      loadingOTP ||
      updatingWallet ||
      isEvertecLoading ||
      fetchingTaxpayerId ||
      isYunoLoading;
    const isPointsPaying =
      (walletPointsUsed || walletBalanceUsed || walletMinimumPointsToBeUsed) && total === 0;

    const hasDiscountCode = features.DISCOUNT_CODE_ENABLED;
    const displayCostapassSlider =
      selectedLoyaltyProgram === 'costapass' || selectedLoyaltyProgram === 'travelpass';
    const dotersEnabled = features.DOTERS_ENABLED;
    const dotersIsActiveForPurchase = dotersEnabled && walletType === 'doters';
    const displayDotersBanner = dotersEnabled && !dotersIsActiveForPurchase;
    const displayDotersSlider = dotersIsActiveForPurchase;
    const showDotersPointsEarned = dotersIsActiveForPurchase;
    const hideServices =
      !enablesInsurance &&
      !showMessageNotInsuranceOpenTicket &&
      !enablesCarbon &&
      !isExchange &&
      !displayCostapassSlider &&
      !displayDotersBanner &&
      !displayDotersSlider &&
      !showDotersPointsEarned &&
      !hasDiscountCode;
    const onlyDiscount =
      !enablesInsurance &&
      !showMessageNotInsuranceOpenTicket &&
      !enablesCarbon &&
      !isExchange &&
      !displayCostapassSlider &&
      !displayDotersBanner &&
      !displayDotersSlider &&
      !showDotersPointsEarned &&
      hasDiscountCode;

    const showAgreementCheckboxFields =
      features.SHOW_LEGAL_TERMS_CHECKBOX || features.SHOW_PROMOTIONAL_TERMS_CHECKBOX;

    const servicesComponents = (
      <Spacing vertical size="S">
        <Text weight="bold" size="L">
          {t('purchase:label.additional_services')}
        </Text>
        <Spacing vertical size="S">
          <DotersBanner show={!user} onPayment />
          <DotersSlider />
          <CostaPassSlider />
        </Spacing>
        {enablesInsurance && (
          <InsuranceAncillarySwitch
            checked={wantsInsurance}
            isLoading={updatingInsurance}
            onChange={this.toggleWantsInsurance}
            message={insuranceMessage}
            title={insuranceTitle}
            price={insuranceAmount}
            singlePrice={insuranceAmountIsUnitPrice && !showMessageNotInsuranceOpenTicket}
            plusTaxes={insuranceAmountIsUnitPrice && !features.INSURANCE_ON_CHECKOUT_ONLY}
          />
        )}
        {showMessageNotInsuranceOpenTicket && (
          <>
            <OpenTicketMessage isLoggedIn={Boolean(user)} />
            <InfoText>{t('payment:text.insurance_not_available_for_open_ticket')}</InfoText>
          </>
        )}
        {enablesCarbon && (
          <AncillariesSwitch
            isLoading={updatingCarbonOffset}
            id="carbon-footprint"
            title={carbonOffset?.title}
            icon={carbonOffset?.imageUrl}
            message={carbonOffset?.description}
            linkText={t('purchase:link.see_more')}
            href={carbonOffset?.urls}
            onChange={this.toggleWantsCarbonOffset}
            checked={wantsCarbonOffset}
            price={carbonOffset?.amount}
          />
        )}
        {!isExchange && <DiscountCode />}
      </Spacing>
    );

    return (
      <>
        {shouldRenderLoading ? <Loading /> : null}
        <div
          className={`checkout-container ${isLoading ? 'disabled' : ''}`}
          style={{ display: shouldRenderLoading ? 'none' : 'block' }}
        >
          <Spacing vertical>
            <Spacing size="XL" vertical>
              {bulkTicket && <PassengerSelectorBox />}

              <ReturnCTA type="card" />

              <PriceChangedModal />
              {onlyDiscount ? (
                <Visibility type="hideForLargeOnly">{servicesComponents}</Visibility>
              ) : (
                <>{!hideServices && <>{servicesComponents}</>}</>
              )}
              {(!isUpdating || purchaserFormInCheckoutStep) && (
                <PaymentOptions
                  availablePayments={availablePayments}
                  paymentMethods={paymentMethods}
                  submitPurchaser={this.onSubmitPurchaser}
                  submitCard={this.cardPaymentAttempt}
                  submitCardFail={this.handleFormErrors}
                  submitTransfer={this.onSubmitTransferForm}
                  selectedOption={selectedPaymentOption}
                  onPaymentTypeChange={this.setPaymentType}
                  isOpenTicket={openTicket}
                  cardErrorOccured={cardErrorOccured}
                  hideOptions={total === 0}
                  showPurchaserForm={features.PURCHASER_FORM_ON === 'CHECKOUT'}
                  isExchange={isExchange}
                  removeTopMargin={onlyDiscount}
                />
              )}
              {features.CAN_USE_POINTS &&
                walletType !== 'doters' &&
                walletType !== 'travelpass' &&
                walletType !== 'costapass' &&
                user?.email && <CheckoutWallet />}
            </Spacing>

            <BookingReview
              departureDate={departs.departure}
              returnDate={returns.departure}
              passengers={passengers}
              isDepartureOpen={wayIsOpenTicket(departs)}
              isReturnOpen={wayIsOpenTicket(returns)}
            />

            {features.POLICIES_DISCLAIMER_LOCATION === 'CHECKOUT' &&
              (showAgreementCheckboxFields ? (
                <AgreementCheckboxFields />
              ) : (
                <PassengerPolicies location="checkout" />
              ))}
            <DotersModalOtp userEmail={user?.email} visible={showOTPModal} isLoading={loadingOTP} />
            <DotersModalUsage
              visible={showDotersModal}
              walletPointsUsed={parseFloat(walletTotalPointsUsed) || parseFloat(walletPointsUsed)}
              walletBalanceUsed={
                parseFloat(walletTotalBalanceUsed) || parseFloat(walletBalanceUsed)
              }
              handleClick={this.handleDotersUsageModalButtonClick}
              currency={env.currency}
            />
            <CheckoutNavigation leftContent={showInstallments && installmentNotice}>
              {!isUpdating && (
                <PaymentButton
                  paymentType={paymentType}
                  totalPrice={selectedPaymentTotal}
                  onClick={this.paymentButtonClick}
                  isExchange={isExchange}
                  isLoading={buttonLoading}
                  isDisabled={updatingInsurance || applyingDiscount || buttonLoading}
                  isPointsPaying={isPointsPaying}
                  walletText={this.getWalletPointsText()}
                />
              )}
            </CheckoutNavigation>
            {user && <SSOAuthDialog />}
          </Spacing>
        </div>
      </>
    );
  }
}

Checkout.propTypes = propTypes;
Checkout.contextType = MercadoPagoTDCContext;

const mapStateToProps = (state) => ({
  features: state.whitelabelConfig.features,
  env: state.whitelabelConfig.env,
  dispatch: state.dispatch,
});

export default connect(mapStateToProps)(withTranslation('payment')(Checkout));
