import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import 'styles/components/PaymentStatusIcon';
import usePurchase from 'hooks/store/usePurchase';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelTheme from '../../../hooks/whitelabel/useWhitelabelTheme';

/**
 * PaymentStatusIcon Component
 *
 * Component for displaying payment status icons based on the provided title status. It dynamically determines the appropriate icon to show, considering different payment status scenarios.
 *
 * @component
 * @param {string} titleStatus - The payment status title to determine the icon.
 *
 * @returns {JSX.Element} - The rendered PaymentStatusIcon component.
 */

function getIconName(titleStatus) {
  switch (titleStatus) {
    case 'pending':
    case 'process':
      return 'process';
    case 'pendingCompleted':
    case 'completed':
      return 'success';
    default:
      return titleStatus;
  }
}

const PaymentStatusIcon = ({ titleStatus }) => {
  const { paymentStatusIcons } = useWhitelabelTheme();
  const { BULK_TICKET_ENABLED } = useWhitelabelFeatures();
  const { bulkTicket: isBulkTicket, departs } = usePurchase();
  const lines = useSelector((state) => state.lines);
  const departureFragment = departs?.fragments?.[0] ?? null;
  const lineId = departureFragment?.line.abbr;

  const isConecta = BULK_TICKET_ENABLED;
  let paymentIconName = getIconName(titleStatus);

  if (isConecta) {
    if (isBulkTicket && lines) {
      // Get a random abbr from the lines objects
      const linesArray = Object.values(lines);
      const randomLine = linesArray[Math.floor(Math.random() * linesArray.length)];
      paymentIconName = `${paymentIconName}_${randomLine.abbr}`;
    } else if (lineId) {
      paymentIconName = `${paymentIconName}_${lineId}`;
    }
  }

  const customPaymentStatusIconSrc = paymentStatusIcons[paymentIconName];
  const isGif =
    customPaymentStatusIconSrc && customPaymentStatusIconSrc.toLowerCase().endsWith('.gif');
  const animatedIconClass = isGif ? 'payment-status-icon-animated' : 'payment-status-icon';

  return customPaymentStatusIconSrc ? (
    <img src={customPaymentStatusIconSrc} alt={paymentIconName} className={animatedIconClass} />
  ) : (
    <i className={`payment-status-icon-${paymentIconName}`} />
  );
};

PaymentStatusIcon.propTypes = {
  titleStatus: PropTypes.string.isRequired,
};

export default PaymentStatusIcon;
