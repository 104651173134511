import { useState, useCallback, useEffect, useRef } from 'react';
import base32 from 'hi-base32';
import { useDispatch, useSelector } from 'react-redux';
import usePurchase from 'hooks/store/usePurchase';
import { setError } from '../../actions';
import adyen from '../../../payments/core/engines/adyen';
import { cardPaymentAttempt } from '../../actions/payment';

function useAdyen(adyenElement, showAdyenForm) {
  const dispatch = useDispatch();
  const requestedPayment = useRef(false);

  const { env } = useSelector((state) => state.whitelabelConfig);
  const { currency } = env;

  const {
    token: purchaseToken,
    total: purchaseTotal,
    selectedPaymentOption,
    monthlySelectedPlan,
  } = usePurchase();

  const [firstDigits, setFirstDigits] = useState(null);
  const [lastDigits, setLastDigits] = useState(null);
  const [cardBrand, setCardBrand] = useState(null);
  const [holderNameValid, setHolderNameValid] = useState(true);

  function onBinValue({ binValue }) {
    setFirstDigits(binValue);
  }

  function onFieldValid({ fieldType, endDigits }) {
    if (fieldType !== 'encryptedCardNumber') return;
    setLastDigits(endDigits);
  }

  function onBrand({ brand }) {
    setCardBrand(brand);
  }

  const validateHolderName = useCallback((value) => {
    const nameRegex = /^[a-zA-ZÀ-ÿ\s]{1,100}$/;
    return nameRegex.test(value);
  }, []);

  const ADYEN_CLASSES = {
    input: {
      error: 'adyen-checkout__input--error',
      valid: 'adyen-checkout__input--valid',
    },
    validation: {
      wrapper: 'adyen-checkout-input__inline-validation',
      valid: 'adyen-checkout-input__inline-validation--valid',
      invalid: 'adyen-checkout-input__inline-validation--invalid',
    },
    icon: 'adyen-checkout__icon',
  };

  const ICONS = {
    error: {
      alt: 'field_error',
      src: 'https://checkoutshopper-test.adyen.com/checkoutshopper/images/components/field_error.svg',
    },
    valid: {
      alt: 'checkmark',
      src: 'https://checkoutshopper-test.adyen.com/checkoutshopper/images/components/checkmark.svg',
    },
  };

  /**
   * Validates the cardholder name and manages error states in the UI.
   * This function is memoized using useCallback to prevent unnecessary re-renders.
   *
   * @param {string} holderName - The cardholder name to validate
   * @returns {void}
   *
   * The function:
   * - Returns early if holderName is empty
   * - Validates the name using validateHolderName regex
   * - Replaces clases depending on the validation result
   */
  const handleHolderNameValidation = useCallback(
    (holderName) => {
      if (!holderName) return;

      const isValid = validateHolderName(holderName);
      const holderNameInput = document.querySelector('input[id*=adyen-checkout-holderName]');
      const validationWrapper = holderNameInput?.parentElement?.querySelector(
        `.${ADYEN_CLASSES.validation.wrapper}`,
      );

      if (!holderNameInput || !validationWrapper) return;

      /**
       * Updates the validation state UI for the cardholder name input
       * @param {boolean} isValidState - Whether the input is valid
       */
      const updateValidationState = (isValidState) => {
        holderNameInput.style.color = isValidState ? '' : '#ff040d';
        holderNameInput.classList.toggle(ADYEN_CLASSES.input.error, !isValidState);
        holderNameInput.classList.toggle(ADYEN_CLASSES.input.valid, isValidState);

        // Hide all existing icons
        Array.from(validationWrapper.children).forEach((child) => {
          child.classList.add('hidden');
        });

        // Update validation wrapper classes
        validationWrapper.classList.toggle(ADYEN_CLASSES.validation.invalid, !isValidState);
        validationWrapper.classList.toggle(ADYEN_CLASSES.validation.valid, isValidState);

        // Show appropriate icon
        const iconConfig = isValidState ? ICONS.valid : ICONS.error;
        let icon = validationWrapper.querySelector(`img[alt="${iconConfig.alt}"]`);

        if (!icon) {
          icon = document.createElement('img');
          icon.className = ADYEN_CLASSES.icon;
          icon.alt = iconConfig.alt;
          icon.src = iconConfig.src;
          validationWrapper.insertBefore(icon, validationWrapper.firstChild);
        }

        icon.classList.remove('hidden');
      };

      if (!isValid) {
        updateValidationState(false);
        setHolderNameValid(false);
      } else if (isValid && !holderNameValid) {
        updateValidationState(true);
        setHolderNameValid(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validateHolderName, holderNameValid, setHolderNameValid],
  );

  /**
   * Handles validation errors from the Adyen form
   */
  function onError() {
    dispatch(setError(200, 'check_the_fields_in_red', 'warning', false, null, null, null, 'fixed'));
  }

  const onSubmit = useCallback(
    ({ isValid, data }) => {
      if (!isValid || requestedPayment.current) return;
      const { browserInfo, paymentMethod } = data;

      if (!validateHolderName(paymentMethod.holderName)) {
        setHolderNameValid(false);
        dispatch(
          setError(200, 'card_holder_name_error', 'warning', false, null, null, null, 'fixed'),
        );
        return;
      }

      const adyenToken = base32.encode(
        [
          paymentMethod.encryptedCardNumber,
          paymentMethod.encryptedExpiryMonth,
          paymentMethod.encryptedExpiryYear,
          paymentMethod.encryptedSecurityCode,
        ].join(':'),
      );

      const mask = firstDigits.toString().length === 8 ? 'xxxx' : 'xxxxxx';

      const cardValues = {
        holderName: paymentMethod.holderName,
        cardNumber: `${firstDigits}${mask}${lastDigits}`,
        cardBrand,
        monthlySelectedPlan,
      };

      const paymentInfo = {
        adyenToken,
        amount: purchaseTotal,
        currency,
        browserInfo,
      };

      dispatch(cardPaymentAttempt(purchaseToken, cardValues, paymentInfo));
      requestedPayment.current = true;
    },
    [
      cardBrand,
      dispatch,
      firstDigits,
      lastDigits,
      monthlySelectedPlan,
      purchaseToken,
      currency,
      purchaseTotal,
      validateHolderName,
    ],
  );

  useEffect(() => {
    const adyenInstance = adyen.getAdyen();

    if (!adyenElement.current) return;
    if (!adyenInstance) return;
    if (selectedPaymentOption !== 'credit_card') return;
    if (!showAdyenForm) return;

    adyenInstance
      .create('card', {
        hasHolderName: true,
        holderNameRequired: true,
        showPayButton: false,
        amount: {
          value: purchaseTotal * 100,
          currency: 'MXN',
        },
        styles: {
          error: {
            color: '#ff040d',
          },
          validated: {
            color: '#66ba5b',
          },
          placeholder: {
            color: '#c7c7c7',
          },
        },
        onChange: (event) => {
          if (event.data.paymentMethod?.holderName) {
            handleHolderNameValidation(event.data.paymentMethod.holderName);
          }
        },
        onSubmit,
        onFieldValid,
        onBinValue,
        onBrand,
        onError,
      })
      .mount(adyenElement.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adyenElement,
    onSubmit,
    purchaseTotal,
    selectedPaymentOption,
    showAdyenForm,
    handleHolderNameValidation,
  ]);

  useEffect(() => {
    const adyenInstance = adyen.getAdyen();
    if (!adyenInstance) return;
    adyenInstance.update('card', {
      amount: {
        value: purchaseTotal * 100,
        currency: env.currency,
      },
    });
  }, [env.currency, purchaseTotal]);
}

export default useAdyen;
