import React from 'react';
import { Text, BadgeRounded, Spacing, ProviderLogo } from '@reservamos/elements';
import PropTypes from 'prop-types';
import './DiscountBanner.scss';
import { useTranslation } from 'react-i18next';
import useSearch from 'hooks/store/useSearch';
import { useSelector } from 'react-redux';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import Amenity from './Amenity';

/**
 * Renders the DiscountBanner component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.discountPercent - The discount percentage.
 * @returns {JSX.Element} The rendered component.
 */
const DiscountBanner = ({ discountPercent }) => {
  const { t } = useTranslation('common');
  const { SHOW_DIGITAL_DISCOUNT_BANNER, SHOW_DEFAULT_AMENITIES } = useWhitelabelFeatures();
  const { departure: searchDeparture } = useSearch();
  const lines = useSelector((state) => state.lines);
  const { routeType } = searchDeparture;
  const isBulkTicket = routeType === 'bulk_ticket';

  if (routeType === 'default') return null;

  return (
    <div className="banner-discount">
      {SHOW_DIGITAL_DISCOUNT_BANNER && (
        <Spacing size="XS" alignItems="center">
          <BadgeRounded
            equalRadius
            size="M"
            text={discountPercent}
            firstColor="discountLight"
            secondColor="discountStrong"
          />
          <Text mobileSize="XXS" size="S" color="grayStrong" italic>
            {t('common:labels.digital_purchase')}
          </Text>
        </Spacing>
      )}
      <Spacing size="XS" alignItems="center">
        {isBulkTicket && (
          <Spacing size="S" alignItems="center">
            <Text color="grayLight">{t('common:labels.provider')}:</Text>
            {Object.values(lines).map(
              (line) =>
                line.logoUrl && <ProviderLogo imgSrc={line.logoUrl} name={line.abbr} isSmall />,
            )}
          </Spacing>
        )}
        {!SHOW_DIGITAL_DISCOUNT_BANNER && !isBulkTicket && (
          <Text size="XS">{t('general:amenities')}</Text>
        )}
        {
          // TODO add amenities based on the route when available
        }
        {SHOW_DEFAULT_AMENITIES && (
          <>
            <Text color="grayLight"> | </Text>
            <Amenity type="bathroom" isIcon iconSize="S" />
            <Amenity type="tv" isIcon iconSize="S" />
            <Amenity type="wifi" isIcon iconSize="S" />
          </>
        )}
      </Spacing>
    </div>
  );
};

DiscountBanner.propTypes = {
  discountPercent: PropTypes.string,
};

export default DiscountBanner;
