import { useSelector } from 'react-redux';

/**
 * Custom hook to access the search state from Redux.
 * @returns {object} The current search state.
 */
function useSearch() {
  const search = useSelector((state) => state.search.toJS());
  return search;
}

export default useSearch;
