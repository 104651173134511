import React from 'react';
import { useTranslation } from 'react-i18next';
import PricingRow from 'ui/atoms/PricingRow';
import { getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import PropTypes from 'prop-types';

/**
 * PurchasePricingBulk component.
 * @param {object} props - totalsPerCategory, globalTotal, globalTaxes, labelTaxes, labelTotal
 * @param {Array<{category: string, count: number, label: string, total: number}>} props.totalsPerCategory - Lista de totales por categoría
 * @param {number} props.globalTotal - Suma total global
 * @param {number} props.globalTaxes - Total de impuestos global
 * @param {string} props.labelTaxes - Etiqueta para los impuestos
 * @param {string} props.labelTotal - Etiqueta para el total
 */
const PurchasePricingBulk = ({
  totalsPerCategory,
  globalTotal,
  globalTaxes,
  labelTaxes,
  labelTotal,
}) => {
  const { t } = useTranslation('passengers');

  return (
    <div className="section-content">
      {totalsPerCategory.map((item) => (
        <PricingRow
          key={item.category}
          label={`${item.count} ${t(item.label)}`}
          price={item.subtotal.toString()}
          sign={getCurrencyPrefix()}
          currency={getCurrencySuffix()}
        />
      ))}
      <PricingRow
        label={labelTaxes}
        price={globalTaxes.toString()}
        sign={getCurrencyPrefix()}
        currency={getCurrencySuffix()}
        isMain
      />
      <PricingRow
        label={labelTotal}
        price={globalTotal.toString()}
        sign={getCurrencyPrefix()}
        currency={getCurrencySuffix()}
        isMain
      />
    </div>
  );
};

PurchasePricingBulk.propTypes = {
  totalsPerCategory: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    }),
  ).isRequired,
  globalTotal: PropTypes.number.isRequired,
  globalTaxes: PropTypes.number.isRequired,
  labelTaxes: PropTypes.string.isRequired,
  labelTotal: PropTypes.string.isRequired,
};

export default PurchasePricingBulk;
