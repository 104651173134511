import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { purchaseAttemptTracker } from 'metrics/user-analytics/purchase';
import LoadingScreen from 'components/LoadingScreen';
import { isSeatsOnResultActivated } from 'utils/seats';

const propTypes = {
  purchase: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    isExchange: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    departs: PropTypes.object,
    returns: PropTypes.object,
    bulkTicket: PropTypes.bool,
  }).isRequired,
  createPurchase: PropTypes.func.isRequired,
  replaceUrl: PropTypes.func.isRequired,
  search: PropTypes.shape({
    departure: PropTypes.shape({
      routeType: PropTypes.string,
    }),
  }).isRequired,
};

/**
 * NewPurchase component.
 * Handles the creation of a new purchase and redirects based on purchase state and seat selection.
 * @param {object} props - createPurchase, purchase, replaceUrl, search
 * @param {function} props.createPurchase - Function to initialize a new purchase
 * @param {object} props.purchase - Purchase state containing isFetching, isExchange, token, departs, returns
 * @param {function} props.replaceUrl - Function to handle URL navigation
 * @param {object} props.search - Search state including departure route type
 */
class NewPurchase extends Component {
  componentDidMount() {
    const { createPurchase } = this.props;
    createPurchase();
  }

  componentWillReceiveProps(nextProps) {
    const { replaceUrl, purchase } = this.props;
    const nextPurchase = nextProps.purchase;

    const isSeatsOnResults = isSeatsOnResultActivated();

    if (purchase.isFetching && !nextPurchase.isFetching) {
      purchaseAttemptTracker(nextPurchase);
      const redirectUrl = `/purchase/${nextPurchase.token}`;

      // If seats are already selected, the user is redirected to passengers page
      const hasSelectedSeats =
        nextPurchase.departs?.selectedSeats?.length || nextPurchase.returns?.selectedSeats?.length;

      const isBulkTicket = nextPurchase.bulkTicket;

      /**
       * Determines the redirect path based on the given conditions.
       * @param {boolean} isBulkPurchase - If the purchase is in bulk.
       * @param {boolean} hasSelectedSeats - If seats have been selected.
       * @param {boolean} isSeatsOnResults - If seat selection is activated in the results.
       * @returns {string} The redirect path.
       */
      const getRedirectPath = (isBulkPurchase, hasSelectedSeats, isSeatsOnResults) => {
        if (isBulkPurchase) return 'checkout';
        if (hasSelectedSeats && isSeatsOnResults) return 'passengers';
        return 'seats/departure';
      };

      replaceUrl(
        `${redirectUrl}/${getRedirectPath(isBulkTicket, hasSelectedSeats, isSeatsOnResults)}`,
      );
    }
  }

  render() {
    return <LoadingScreen backgroundColor="white" />;
  }
}

NewPurchase.propTypes = propTypes;

export default NewPurchase;
