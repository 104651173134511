import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@reservamos/elements';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import NextHoursRange from 'ui/atoms/NextHoursRange';
import NextHoursDepartures from './NextHoursDepartures';

function ModalNextHoursRange() {
  const { t } = useTranslation();

  const { SHOW_NEXT_DEPARTURES } = useWhitelabelFeatures();

  if (!SHOW_NEXT_DEPARTURES) {
    return null;
  }

  return (
    <Modal
      modalTrigger={
        <button className="w-full rounded-3xl hover:shadow-md transition-shadow duration-200">
          <NextHoursRange border padding icon />
        </button>
      }
      showOverFlowContent
      title={t('general:next_departures')}
      contentHeight="auto"
      responsiveSize="auto"
    >
      <NextHoursDepartures />
    </Modal>
  );
}

export default ModalNextHoursRange;
