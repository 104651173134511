import React, { Component } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import TripSummaryCardSchedule from 'components/purchase/TripSummaryCardSchedule';
import TripSummaryModal from 'components/purchase/TripSummaryModal';
import 'styles/components/purchase/TripSummary';
import { IconText, Spacing } from '@reservamos/elements';

const propTypes = {
  departureDate: PropTypes.object.isRequired,
  departureTrip: PropTypes.object.isRequired,
  destinationCity: PropTypes.string.isRequired,
  originCity: PropTypes.string.isRequired,
  returnDate: PropTypes.object.isRequired,
  returnTrip: PropTypes.object.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  showPurchaseReview: PropTypes.func.isRequired,
  departureOpenTicket: PropTypes.bool.isRequired,
  returnOpenTicket: PropTypes.bool.isRequired,
  returnAdultPrice: PropTypes.number,
  seenAdultPrice: PropTypes.string.isRequired,
  departureLogosToShow: PropTypes.array.isRequired,
  returnLogosToShow: PropTypes.array.isRequired,
  departureServiceType: PropTypes.string,
  features: PropTypes.object.isRequired,
  serviceTypeIcon: PropTypes.string.isRequired,
  serviceTypeLabel: PropTypes.string.isRequired,
};

class TripSummaryCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      departureDate,
      departureTrip,
      returnTrip,
      destinationCity,
      originCity,
      returnDate,
      roundTrip,
      showPurchaseReview,
      departureOpenTicket,
      returnOpenTicket,
      seenAdultPrice,
      returnAdultPrice,
      departureLogosToShow,
      returnLogosToShow,
      departureServiceType,
      features,
      serviceTypeIcon,
      serviceTypeLabel,
    } = this.props;
    const { showModal } = this.state;
    const shouldShowServiceTypeBadge = features.SHOW_SERVICE_TYPE_BADGE && departureServiceType;

    return (
      <div className="trip-summary-layout">
        <TripSummaryCardSchedule
          label={roundTrip && i18n.t('trips:one_way_trip')}
          dateTime={departureDate}
          destinationCity={destinationCity}
          logoUrl={departureLogosToShow}
          originCity={originCity}
          isOpenTicket={departureOpenTicket}
          seenAdultPrice={seenAdultPrice}
          service={departureTrip.fragments[0].service}
        />
        {roundTrip && (
          <>
            <TripSummaryCardSchedule
              label={roundTrip && i18n.t('trips:return_trip')}
              dateTime={returnDate}
              destinationCity={originCity}
              logoUrl={returnLogosToShow}
              originCity={destinationCity}
              isOpenTicket={returnOpenTicket}
              returnAdultPrice={returnAdultPrice}
              service={returnTrip.fragments[0].service}
            />
          </>
        )}

        <div className="trip-summary-link">
          <Spacing>
            <a onClick={this.openModal}>{i18n.t('general:show_details')}</a>
            <a onClick={showPurchaseReview}>{i18n.t('general:show_details')}</a>
            {shouldShowServiceTypeBadge && (
              <IconText
                iconType={serviceTypeIcon}
                label={serviceTypeLabel}
                iconSize="S"
                fontSize="XS"
              />
            )}
          </Spacing>
        </div>
        <TripSummaryModal
          visible={showModal}
          hideModal={this.closeModal}
          departureTrip={departureTrip}
          returnTrip={returnTrip}
          roundTrip={roundTrip}
          showModal={showModal}
        />
      </div>
    );
  }
}

TripSummaryCard.propTypes = propTypes;

export default TripSummaryCard;
