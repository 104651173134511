/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { decamelize } from 'humps';
import { useTranslation } from 'react-i18next';
import { Gap, Icon, ResultBox, Text, Visibility } from '@reservamos/elements';
import FlightItem from 'components/search/FlightItem';
import useGrowthBookFeaturesValue from 'components/GrowthBookProvider/useGrowthBookFeaturesValues';
import MixedItem from 'components/search/MixedItem';
import ResultDefault from 'components/search/ResultDefault';
import SimpleTripResult from 'components/search/SimpleTripResult';
import TripResult from 'components/search/TripResult';
import ResultSpecifics from 'components/search/ResultSpecifics';
import ResultBoxHorizontal from 'components/search/ResultBoxHorizontal';
import dotersDot from 'images/doters/greendoters.svg';
import { useSelector } from 'react-redux';
import SeatSelectorFactory from 'components/seats/SeatSelectorFactory';
import { useHistory } from 'react-router-dom';
import { getProductType } from 'utils/Reserbus';
import { isSeatsOnResultActivated } from 'utils/seats';
import {
  checkRecentTripSeen,
  cleanPrevRecentSeenTrip,
  deleteExpiredRecentSeenTrips,
  saveCacheRecentSeenTrip,
} from 'utils/trips/cacheTrip';
import useWhitelabelTheme from '../../hooks/whitelabel/useWhitelabelTheme';
import ResultWithIcons from './ResultWithIcons';
import { flatFareDiscountTotal } from '../../utils/doters';
import 'styles/components/search/ResultBox';
import ResultGfa from './ResultGfa';
import useSearchCouponSelection from './SearchCoupon/SearchCoupon.hooks';
import '../../styles/components/search/TripItem';

const TripItem = (props) => {
  const { t } = useTranslation();
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { resultPriceToShow } = useSelector((state) => state.search?.toJS() || {});
  const { resultBoxStyle } = useWhitelabelTheme();
  const isNewResultsDesign = useGrowthBookFeaturesValue('new_results_design');

  const { couponLoading } = useSearchCouponSelection();

  const [showBusDiagram, setShowBusDiagram] = useState(false);

  const history = useHistory();
  const isDesktop = getProductType() === 'desktop';

  const {
    total,
    trip,
    type,
    position,
    selectTrip,
    id,
    isFromOpenTicketList,
    filtersApplied,
    isExchange,
    availability,
    isRoundTrip,
    way,
    isLogged,
    departureTrip,
    rewardTicketStatus,
    setRewardTicketStatus,
    highLightAsMobile,
    allowsSeatSelection,
  } = props;

  const isSeatsOnResults = isSeatsOnResultActivated() && allowsSeatSelection;

  /**
   * useEffect hook that cleans up previous recently seen trips when the component unmounts.
   */
  useEffect(() => {
    return () => {
      cleanPrevRecentSeenTrip();
    };
  }, []);

  /**
   * Checks if the trip has an error when fetching the details to close it
   */

  let content;

  const hasFilters =
    !isExchange &&
    (!filtersApplied.departureTime?.includes('none') ||
      filtersApplied.categories?.length ||
      filtersApplied.tripOptions?.length ||
      !filtersApplied.stops?.includes('none'));

  const showNewFiltersList = features.NEW_FILTERS_VERSION && hasFilters && !isExchange;
  let { pricing: pricingKey } = trip;

  if (isRoundTrip)
    pricingKey = way === 'departure' ? trip.departureRoundTripPricing : trip.roundTripPricing;
  const { walletPoints, totalBase, discountBase, providerDiscount } = pricingKey;

  const { flatFareValid } = flatFareDiscountTotal({
    way,
    trip,
    isRoundTrip,
    departureTrip,
    isFlatFareEnabled: features.FLAT_FARE_ENABLED,
  });
  const showFlatFare = flatFareValid === 'valid';
  const isRecentlySeen = checkRecentTripSeen(trip.id);

  /**
   * Function to handle selecting a trip item
   */
  const onSelectTrip = () => {
    selectTrip(trip, position);
    deleteExpiredRecentSeenTrips();
  };

  /**
   * Handles the selection of a trip item, showing the bus diagram if applicable.
   */
  const handleOnSelect = () => {
    const isRedirect = features.TRIP_REDIRECTION_LEAD && trip.redirectTo;
    if (isSeatsOnResults && !isExchange && !isRedirect) {
      if (showBusDiagram) {
        cleanPrevRecentSeenTrip();
      }
      setShowBusDiagram((prevState) => !prevState);
      saveCacheRecentSeenTrip(trip.id);
      return;
    }

    if (features.FLAT_FARE_ENABLED && way === 'return') {
      if (
        flatFareValid === 'noExists' ||
        flatFareValid === 'valid' ||
        rewardTicketStatus.status === 'continue'
      ) {
        onSelectTrip();
      }
      if (flatFareValid === 'invalid') {
        setRewardTicketStatus({
          status: 'invalid',
          onAccept: () => onSelectTrip(),
        });
      }
    } else {
      onSelectTrip();
    }
  };

  /* Prices */
  const priceTotal = (showFlatFare && totalBase) || total;
  const discountAmount = providerDiscount ? providerDiscount.amount : 0;
  const discount = discountBase || discountAmount;
  const windowTicketPrice = priceTotal + discount;
  const itemProps = {
    ...props,
    tripId: id,
    hasFilters,
    useFlatFare: flatFareValid,
    showNewFiltersList,
    walletPoints,
    ...((isLogged || isExchange) && {
      totalBase,
    }),
    discountBase,
    isExchange,
    onSelectClick: handleOnSelect,
    showWalletPoints: resultPriceToShow.priceType === 'wallet',
    walletType: resultPriceToShow.walletType,
    total: priceTotal,
    windowTicketPrice,
    highLightType: trip.recommendationConcept,
    highlightText: `${t(`trips:recommended_trips.${decamelize(trip.recommendationType || '')}`)}`,
    highLightAsMobile,
    loadingPrice: couponLoading,
    recentView: isRecentlySeen,
    isSeatsOnResults,
  };

  if (!isFromOpenTicketList && itemProps.trip && itemProps.trip.openTicket) {
    return null;
  }

  // TODO: Remove this when the AB test is finished and the config is set with the correct values
  const resultsForAb = {
    'berlinas': 'simpleResult',
    'rochoa': 'simpleResult',
    'roll-bits': 'simpleResult',
    'gfa': 'simpleResult',
    'etn': 'result',
    'gho': 'result',
    'costaline': 'result',
    'brasilia': 'result',
    'default': features.SCHEDULE_RESULT_TYPE,
  };

  let resultType = features.SCHEDULE_RESULT_TYPE;
  if (isNewResultsDesign) {
    resultType = resultsForAb[env.brand] || resultsForAb.default;
  }

  const scheduleBox = {
    default: <ResultDefault {...itemProps} />,
    GFA: <ResultGfa {...itemProps} />,
    specifics: <ResultSpecifics {...itemProps} />,
    horizontal: (
      <>
        <Visibility type="hideForMobileOnly">
          <ResultBoxHorizontal {...itemProps} />
        </Visibility>
        <Visibility type="showForMobileOnly">
          <ResultSpecifics {...itemProps} />
        </Visibility>
      </>
    ),
    icons: <ResultWithIcons {...itemProps} />,
    result: (
      <TripResult {...itemProps} isOpen={Boolean(showBusDiagram && isDesktop)}>
        <SeatSelectorFactory
          way={way}
          trip={trip}
          fromTrip={departureTrip}
          isPurchaseView={false}
          finishSeatsSelection={onSelectTrip}
          resultsLayout
          isRoundTrip={isRoundTrip}
        />
      </TripResult>
    ),
    simpleResult: (
      <SimpleTripResult {...itemProps} isOpen={Boolean(showBusDiagram && isDesktop)}>
        <SeatSelectorFactory
          way={way}
          trip={trip}
          fromTrip={departureTrip}
          isPurchaseView={false}
          finishSeatsSelection={onSelectTrip}
          resultsLayout
          isRoundTrip={isRoundTrip}
        />
      </SimpleTripResult>
    ),
  };

  switch (itemProps.type || type) {
    case 'bus':
      content = scheduleBox[resultType] || <ResultDefault {...itemProps} />;
      break;
    case 'flight':
      content = <FlightItem {...itemProps} />;
      break;
    case 'mixed':
      content = <MixedItem {...itemProps} />;
      break;
    default:
      content = <div />;
  }

  const { enabled: lastSeatsEnabled, quantity: lastSeatsMin } = features.LAST_SEATS_MESSAGE || {};
  const lastSeatMessages =
    lastSeatsEnabled && availability <= lastSeatsMin ? (
      <Text color="white" size="S" weight="semibold">
        {t('trips:label.last_seats_available')} 📢
      </Text>
    ) : null;

  let notice = lastSeatMessages;
  let noticePosition = false;
  let noticeBg = false;

  const dotersMessage = (
    <Gap alignItems="center">
      <Text color="white" size="S">
        {t('purchase:label.reward_ticket', { ...(isRoundTrip && { context: way }) })}
      </Text>
      <Icon type={dotersDot} size="S" />
    </Gap>
  );

  const noValidFlatFare = (
    <Gap alignItems="center">
      <Text color="grayStrong" size="S">
        {t('purchase:label.reward_ticket_invalid', { context: features.FLAT_FARE_CONTEXT })}
      </Text>
    </Gap>
  );

  if (flatFareValid && !isExchange) {
    if (flatFareValid === 'valid') notice = dotersMessage;
    if (flatFareValid === 'invalid') notice = noValidFlatFare;
    if (flatFareValid === 'valid' || flatFareValid === 'invalid') noticePosition = 'right';
    if (flatFareValid === 'valid') noticeBg = 'grayStrong';
    if (flatFareValid === 'invalid') noticeBg = 'warning';
  }

  const withoutResultBox =
    isNewResultsDesign === true || resultType === 'result' || resultType === 'simpleResult';

  return withoutResultBox ? (
    <>
      <div className={trip.recommendationConcept ? 'recommended-trip-container' : ''}>
        {content}
      </div>
      {showBusDiagram && !isDesktop && (
        <SeatSelectorFactory
          onCloseModal={() => setShowBusDiagram(false)}
          trip={trip}
          way={way}
          history={history}
          finishSeatsSelection={onSelectTrip}
          fromTrip={departureTrip}
          isPurchaseView={false}
          resultsLayout
          isRoundTrip={isRoundTrip}
        />
      )}
    </>
  ) : (
    <ResultBox
      onClick={isNewResultsDesign === false ? null : handleOnSelect}
      notice={notice}
      noticePosition={noticePosition}
      noticeBg={noticeBg}
      flat={resultBoxStyle === 'flat'}
      outline={resultBoxStyle === 'outline'}
    >
      {content}
    </ResultBox>
  );
};

TripItem.propTypes = {
  trip: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  selectTrip: PropTypes.func.isRequired,
  isFromOpenTicketList: PropTypes.bool,
  type: PropTypes.string,
  availability: PropTypes.number.isRequired,
  filtersApplied: PropTypes.object,
  isExchange: PropTypes.bool,
  isRoundTrip: PropTypes.bool,
  way: PropTypes.string.isRequired,
  isLogged: PropTypes.bool,
  departureTrip: PropTypes.object,
  rewardTicketStatus: PropTypes.object,
  setRewardTicketStatus: PropTypes.func,
  total: PropTypes.number.isRequired,
  highLightAsMobile: PropTypes.bool,
  allowsSeatSelection: PropTypes.bool,
};

TripItem.defaultProps = {
  type: 'bus',
};

export default TripItem;
