import { connect } from 'react-redux';
import { setError } from '@/actions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { comparePassengers } from 'utils/passengerUtils';
import {
  dispatchFinishSeatSelection,
  dispatchResetPassengers,
} from '../../../features/purchase/redux/dispatchers';
import PurchaseBreadcrumbs from './PurchaseBreadcrumbs';

const mapStateToProps = (state) => {
  const { purchase, form } = state;
  const allowsSeatSelection = purchase.get('allowsSeatSelection');
  const roundTrip = purchase.get('roundTrip');
  const token = purchase.get('token');
  const hasSelectedSeats = purchase.get('hasSelectedSeats');
  const passengers = purchase.get('passengers');
  const formPassengers = form?.passengers?.values?.passengers;
  const hasPassengers = Boolean(passengers.size > 0) && Boolean(purchase.get('hasTickets'));
  const hasUnsavedChanges =
    hasPassengers && formPassengers ? !comparePassengers(passengers, formPassengers) : false;
  const showPassengersStep = !purchase.get('bulkTicket');
  let stepCount = 1;

  // If we can select seats, there is a step for that
  if (allowsSeatSelection) stepCount += 1;
  // If it is not bulk ticket, then we have a step for passengers data
  if (showPassengersStep) stepCount += 1;

  return {
    roundTrip,
    token,
    allowsSeatSelection,
    hasSelectedSeats,
    hasPassengers,
    hasUnsavedChanges,
    funnelStepCount: stepCount,
    showPassengersStep,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNotification: (msg) => dispatch(setError(1, msg, 'warning', false)),
    finishSeatSelection: (history) => dispatch(dispatchFinishSeatSelection(history)),
    resetPassengers: () => dispatch(dispatchResetPassengers()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(PurchaseBreadcrumbs);
