import React from 'react';
import { useDispatch } from 'react-redux';
import usePurchase from 'hooks/store/usePurchase';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Box, Icon, Gap, Button } from '@reservamos/elements';
import moment from 'moment';
import PropTypes from 'prop-types';
import { togglePurchaseReviewOverlay } from '@/actions/overlayView';
import './BookingReview.scss';
import { trackEvent } from 'user-analytics';
import { CHECKOUT_DETAILS_CLICKED } from '../../constants/TrackEvents';
import useWhitelabelFeatures from '../../hooks/whitelabel/useWhitelabelFeatures';
import NextHoursRange from './NextHoursRange';
import passengerTypes from '../../config/passengerTypes';

/**
 * PassengerBox component.
 * Renders a box containing passenger information.
 * @param {object} props - children
 * @param {node} props.children - Content to be displayed inside the box
 */
const PassengerBox = ({ children }) => (
  <Box
    elementType="div"
    paddingVertical="S"
    paddingHorizontal="M"
    borderRadius="L"
    bgColor="accent"
    alphaBg="XS"
  >
    <Text weight="bold" size="S" textAlign="center">
      {children}
    </Text>
  </Box>
);

const BookingReview = ({
  departureDate,
  returnDate,
  passengers,
  isDepartureOpen,
  isReturnOpen,
}) => {
  const dispatch = useDispatch();
  const features = useWhitelabelFeatures();
  const { bulkTicket: isBulkTicket } = usePurchase();
  const isFlat = features.FUNNEL_STYLE === 'FLAT';
  const { t } = useTranslation(['general', 'passengers']);
  const departureText = isDepartureOpen ? t('open_ticket') : moment(departureDate).format('DD MMM');
  const returnText = isReturnOpen ? t('open_ticket') : moment(returnDate).format('DD MMM');
  const hasFewPassengers = passengers.length < 2;

  if (!features.BOOKING_REVIEW_ENABLED) return null;

  const handleOnClick = () => {
    trackEvent(CHECKOUT_DETAILS_CLICKED);
    dispatch(togglePurchaseReviewOverlay(true));
  };

  /**
   * PassengerCategories component.
   * Renders a list of passengers or passenger categories based on ticket type.
   * @returns {JSX.Element} List of passengers or categories
   */
  const PassengerCategories = () => {
    if (!isBulkTicket) {
      const displayPassengers = passengers.slice(0, 2);
      const remainingPassengers = passengers.length - 2;

      return (
        <>
          {displayPassengers.map((passenger) => (
            <PassengerBox key={passenger.id}>
              {`${passenger.firstName} ${passenger.lastName}`}
            </PassengerBox>
          ))}
          {remainingPassengers > 0 && (
            <Text weight="bold" size="S" textAlign="center">
              {`+${remainingPassengers}`}
            </Text>
          )}
        </>
      );
    }

    const categoryCounts = passengers.reduce((acc, { busCategory }) => {
      const category = busCategory === 'special' ? 'pcd' : busCategory;
      acc[category] = (acc[category] || 0) + 1;
      return acc;
    }, {});

    const sortedCategories = Object.entries(categoryCounts)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 2);
    const remainingCategories = Object.keys(categoryCounts).length - 2;

    return (
      <>
        {sortedCategories.map(([category, count]) => (
          <PassengerBox key={category}>
            {`${count} ${t(`passengers:${passengerTypes[category].label}`)}`}
          </PassengerBox>
        ))}
        {remainingCategories > 0 && (
          <Text weight="bold" size="S" textAlign="center">
            {`+${remainingCategories}`}
          </Text>
        )}
      </>
    );
  };

  return (
    <div>
      <Text weight="bold" size="L">
        {t('trip_summary')}
      </Text>

      <div className="booking-review-wrapper">
        <div className="booking-review-hole-left">
          <div className="booking-review-hole-left-fill" />
        </div>
        <div className="booking-review-hole-right">
          <div className="booking-review-hole-right-fill" />
        </div>
        <div className="booking-review-container">
          <Spacing size="S" vertical>
            <Spacing
              isResponsive
              vertical={!hasFewPassengers || isBulkTicket}
              justifyContent="space-between"
              size="S"
            >
              <Spacing alignItems="center" responsiveSize="S" justifyContent="space-between">
                <Text weight="bold" mobileSize="S">
                  {t(returnDate ? 'round_trip' : 'one_way_trip')}
                </Text>
                <Spacing alignItems="center" justifyContent="center" size="S">
                  <Gap columnGap="XS" alignItems="center">
                    <Icon size="S" type="CalendarOutlined" />
                    <Text weight="bold" size="S">
                      {t('departure')}
                    </Text>
                    <Text size="S" color="grayMedium">
                      {departureText}
                    </Text>
                  </Gap>
                  {returnDate && (
                    <Gap columnGap="XS" alignItems="center">
                      <Icon size="S" type="CalendarOutlined" />
                      <Text weight="bold" size="S">
                        {t('return')}
                      </Text>
                      <Text size="S" color="grayMedium">
                        {returnText}
                      </Text>
                    </Gap>
                  )}
                </Spacing>
              </Spacing>

              <Spacing
                isResponsive
                responsiveColumnReverse
                justifyContent={isBulkTicket ? 'space-between' : 'center'}
              >
                {isBulkTicket && <NextHoursRange />}
                <Spacing alignItems="center" justifyContent="center">
                  <PassengerCategories />
                </Spacing>
              </Spacing>
            </Spacing>
          </Spacing>

          <div className="booking-review-bottom">
            <Button
              color="accent"
              iconType="Info"
              iconColor="accent"
              text={t('trip_details')}
              variant="noFill"
              onClick={handleOnClick}
              isRounded={isFlat}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BookingReview.propTypes = {
  departureDate: PropTypes.string.isRequired,
  returnDate: PropTypes.string.isRequired,
  passengers: PropTypes.array.isRequired,
  isDepartureOpen: PropTypes.bool,
  isReturnOpen: PropTypes.bool,
};

PassengerBox.propTypes = {
  children: PropTypes.node.isRequired,
};

BookingReview.defaultProps = {
  isDepartureOpen: null,
  isReturnOpen: null,
};

export default BookingReview;
