import React from 'react';
import PropTypes from 'prop-types';
import { Text, Spacing, Icon } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import Divider from 'ui/atoms/Divider';

const propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  remainingStops: PropTypes.number.isRequired,
};

const StopCollapse = ({ isCollapsed, onToggle, remainingStops }) => {
  const { t } = useTranslation('trips');

  return (
    <div className="route-detail-row stopover wrap-collapse">
      <div className="rd-left" />

      <div className="rd-center ic-collapse">
        <div className="ic-content">
          {isCollapsed ? (
            <Text weight="bold">+{remainingStops}</Text>
          ) : (
            <Icon type="ChevronUp" size="XS" color="grayMedium" />
          )}
        </div>
      </div>

      <div className="rd-right bus">
        <Spacing size="XS" alignItems="center" fullWidth>
          <Divider />

          <button onClick={onToggle}>
            <Spacing size="XS" alignItems="center">
              {isCollapsed ? (
                <Text whiteSpace="nowrap">
                  {`${t('show_scales')} `}
                  <Text weight="bold" elementType="span">
                    {t('show_scales_how', { count: remainingStops })}
                  </Text>
                </Text>
              ) : (
                <Text whiteSpace="nowrap">{t('less_scales')}</Text>
              )}

              <Icon type={isCollapsed ? 'ChevronDown' : 'ChevronUp'} size="XS" color="grayMedium" />
            </Spacing>
          </button>

          <Divider />
        </Spacing>
      </div>
    </div>
  );
};

StopCollapse.propTypes = propTypes;

export default StopCollapse;
