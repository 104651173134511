import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Gap, Spacing, Text } from '@reservamos/elements';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import HourTag from 'ui/atoms/HourTag';

function NextHoursDepartures() {
  const { t } = useTranslation();

  const { SHOW_NEXT_DEPARTURES } = useWhitelabelFeatures();

  if (!SHOW_NEXT_DEPARTURES) {
    return null;
  }
  return (
    <Spacing vertical size="S">
      <Spacing justifyContent="space-between" alignItems="center">
        <Text weight="bold" size="S">
          {`${t('general:next_departures')} ${' '}`}
        </Text>

        <Spacing size="XS" alignItems="center">
          <Text size="S">{t('general:next_departures_per_minutes')}</Text>

          <HourTag icon="Clock" label="15 min" hasBorder />
        </Spacing>
      </Spacing>

      <Box
        borderRadius="M"
        border="all"
        borderColor="grayBorder"
        paddingHorizontal="S"
        paddingVertical="S"
      >
        <Gap rowGap="S" columnGap="S">
          <HourTag icon="EarlyMorning" label="6:10 am" />
          <HourTag icon="Morning" label="9:15 am" />
          <HourTag icon="Morning" label="9:45 am" />
          <HourTag icon="Afternoon" label="1:40 pm" />
          <HourTag icon="Afternoon" label="5:15 pm" />
          <HourTag icon="Night" label="8:40 pm" />
          <HourTag icon="Night" label="9:50 pm" />
        </Gap>
      </Box>
    </Spacing>
  );
}

export default NextHoursDepartures;
