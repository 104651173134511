import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Map } from 'immutable';
import FilterResultBadge from '../../ui/atoms/FilterResultBadge';
import FilterPlusBadge from '../../ui/molecules/FilterPlusBadge';
import { getScheduleCondition, tripOptionsFilter } from '../../utils/tripFilters';

const BADGES_LIMIT = 2;

/**
 * Component that shows the filters applied in the trip results but limiting the amount showed.
 * @param {Object} props - The component props.
 * @param {Array} props.componentsList - The list of filters to display.
 * @param {boolean} props.minimalDesign - Whether to use commas to separate the filters.
 * @returns {JSX.Element} The rendered component.
 */
const EllipsableFilters = ({ componentsList, minimalDesign }) => {
  const remains = componentsList.length - BADGES_LIMIT;
  const componentsListSliced = componentsList.slice(0, BADGES_LIMIT);
  const componentsLimitIndex = componentsListSliced.length - 1;
  return (
    <>
      {componentsListSliced.map((component, index) => {
        const needsComma =
          minimalDesign && (remains > 0 || (minimalDesign && index < componentsLimitIndex));
        return needsComma ? (
          <div style={{ display: 'flex' }}>
            {component}
            <Text size="S" mobileSize="XS" weight="bold" color="success">
              ,
            </Text>
          </div>
        ) : (
          component
        );
      })}
      <FilterPlusBadge total={remains} minimalDesign={minimalDesign} />
    </>
  );
};

EllipsableFilters.propTypes = {
  componentsList: PropTypes.arrayOf(PropTypes.element).isRequired,
  minimalDesign: PropTypes.bool,
};

/**
 * Component for displaying the filters applied in the trip results.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.filtersApplied - The applied filters.
 * @param {Object} props.trip - The trip details.
 * @param {JSX.Element} props.alternativeComponent - The component to render if there are no filters applied.
 * @param {boolean} props.minimalDesign - Whether to render the badges in a minimal design.
 * @returns {JSX.Element} The rendered component.
 */
const TripFiltersBadges = ({
  filtersApplied,
  trip,
  alternativeComponent,
  minimalDesign,
  renderWhenEmpty,
}) => {
  const { t } = useTranslation();
  const { features } = useSelector((state) => state.whitelabelConfig);
  const isFlat = features.FUNNEL_STYLE === 'FLAT';
  const { departureTime = [], categories = [], stops = [], tripOptions = [] } = filtersApplied;

  /**
   * Get the label for the trip option
   * @param {*} option - The trip option
   * @returns {string} The label for the trip option
   */
  const getTripOptionLabel = (option) => {
    const availableTripOptions = features.TRIP_OPTIONS_FILTER;
    const optionFound = availableTripOptions.find((opt) => {
      if (typeof opt.value === 'object') {
        return JSON.stringify(opt.value) === JSON.stringify(option);
      }
      return opt.value === option;
    });
    if (optionFound) return t(optionFound.i18Key);
    return '';
  };

  // The departure time filters that match are gotten it and the badge is created.
  const matchedCategories = [];
  categories.forEach((category) => {
    if (category === 'supportWoman') {
      if (trip.supportWoman)
        matchedCategories.push(
          <FilterResultBadge key={category} isFlat={isFlat} minimalDesign={minimalDesign}>
            {t(`passengers:type.support_woman`, { context: 'simple' })}
          </FilterResultBadge>,
        );
    }

    const passengerType = trip.passengerTypes.find(
      (passengerType) => passengerType.type === category,
    );
    if (passengerType?.availability > 0) {
      let categoryText = t(`passengers:type.${category}`, {
        context: 'simple',
      });
      const { allowsSeatSelection } = trip;
      // If the seat selection is allowed, the availability is shown, otherwise it doesn't matter.
      // One scenario where the seat selection is not allowed is when it's a bulk ticket.
      const showAvailability = allowsSeatSelection ? `${passengerType.availability} ` : '';
      if (minimalDesign) categoryText = `${showAvailability}${categoryText}`;

      matchedCategories.push(
        <FilterResultBadge key={category} isFlat={isFlat} minimalDesign={minimalDesign}>
          {categoryText}
        </FilterResultBadge>,
      );
    }
  });

  // The stops filters that match are gotten it and the badge is created.
  const matchedStops = [];
  stops.forEach((stop) => {
    if (
      stop === 'none' ||
      (stop === 'direct' && trip.stops > 0) ||
      (stop === 'multiple' && !trip.stops)
    )
      return;

    matchedStops.push(
      <FilterResultBadge key={stop} isFlat={isFlat} minimalDesign={minimalDesign}>
        {t(`search:trip_stopovers`, { context: stop })}
      </FilterResultBadge>,
    );
  });

  // The departure time filters that match are gotten it and the badge is created.
  const matchedDeparture = [];
  departureTime.forEach((time) => {
    if (time === 'none') return;

    if (getScheduleCondition(moment(trip.departure).hours(), time)) {
      matchedDeparture.push(
        <FilterResultBadge key={time} isFlat={isFlat} minimalDesign={minimalDesign}>
          {t(`search:label.selector`, { context: time })}
        </FilterResultBadge>,
      );
    }
  });

  const matchedOptions = [];
  tripOptions.forEach((option) => {
    /* Check if this badge is present on the trip */
    const filtered = tripOptionsFilter(Map(trip), [option]);
    if (!filtered) return null;

    const text = getTripOptionLabel(option);

    matchedOptions.push(
      <FilterResultBadge key={text} isFlat={isFlat} minimalDesign={minimalDesign}>
        {text}
      </FilterResultBadge>,
    );
  });

  // The badges array are joined into a single array in the priority order: categories > stops > departure time.
  const matchedFilters = [
    ...matchedCategories,
    ...matchedStops,
    ...matchedDeparture,
    ...matchedOptions,
  ];

  // If there are filters applied, a limit number of badges are displayed, otherwise the alternative component is displayed.
  if (matchedFilters.length)
    return (
      <Spacing size={minimalDesign ? 'XS' : 'S'}>
        <EllipsableFilters componentsList={matchedFilters} minimalDesign={minimalDesign} />
      </Spacing>
    );

  if (alternativeComponent) return alternativeComponent;
  if (renderWhenEmpty) return renderWhenEmpty;
  return null;
};

TripFiltersBadges.propTypes = {
  filtersApplied: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.string),
    stops: PropTypes.arrayOf(PropTypes.string),
    departureTime: PropTypes.arrayOf(PropTypes.string),
    tripOptions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  trip: PropTypes.object,
  alternativeComponent: PropTypes.element,
  renderWhenEmpty: PropTypes.element,
  minimalDesign: PropTypes.bool,
};

export default TripFiltersBadges;
