import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text, Icon } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import passengerTypes from '../../config/passengerTypes';
import './PassengerQuantitySelector.scss';

/**
 * PassengerQuantitySelector component.
 * @param {object} props - variant, type, onClickIncrement, onClickDecrement, counter, isDisabled.
 * @param {boolean} props.variant - Indicates if it's a variant to adjust styles.
 * @param {string} props.type - The passenger category.
 * @param {function} props.onClickIncrement - Function to increment the counter.
 * @param {function} props.onClickDecrement - Function to decrement the counter.
 * @param {number} props.counter - The current passenger count.
 * @param {boolean} props.isDisabled - Indicates if the selector is disabled.
 */
function PassengerQuantitySelector({
  variant,
  type,
  onClickIncrement,
  onClickDecrement,
  counter,
  isDisabled,
}) {
  const { t } = useTranslation('passengers');
  const { icon, iconVariant, label, disclaimer } = passengerTypes[type];

  return (
    <Spacing justifyContent="space-between" alignItems="center">
      <Spacing size="XS" alignItems="center">
        <Icon type={variant ? iconVariant : icon} size="S" />

        <Spacing size="XXS" vertical>
          <Text weight="bold" mobileSize="S">
            {t(label)}
          </Text>
          {disclaimer && <Text size="S">{t(disclaimer)}</Text>}
        </Spacing>
      </Spacing>

      <Spacing alignItems="center">
        <button
          className={`pasenger-selector-row-button${counter === 0 ? '-less' : ''}`}
          onClick={onClickDecrement}
        >
          <Icon type="LessSign" size="S" color={counter === 0 ? 'grayLight' : 'primary'} />
        </button>

        <div className="w-[30px]">
          <Text mobileSize="L" size="XL" weight="bold" textAlign="center">
            {counter}
          </Text>
        </div>

        <button
          className={`pasenger-selector-row-button${isDisabled ? '-less' : ''}`}
          onClick={onClickIncrement}
          disabled={isDisabled}
        >
          <Icon type="PlusSign" size="S" color={isDisabled ? 'grayLight' : 'primary'} />
        </button>
      </Spacing>
    </Spacing>
  );
}

PassengerQuantitySelector.propTypes = {
  type: PropTypes.oneOf(Object.keys(passengerTypes)).isRequired,
  variant: PropTypes.bool,
};

PassengerQuantitySelector.defaultProps = {
  variant: false,
};

export default PassengerQuantitySelector;

PassengerQuantitySelector.propTypes = {
  onClickIncrement: PropTypes.func,
  onClickDecrement: PropTypes.func,
  counter: PropTypes.number,
  isDisabled: PropTypes.bool,
};
