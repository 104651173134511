import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Spacing, Text } from '@reservamos/elements';
import ProvidersGrid from '../../ui/layouts/ProvidersGrid';

const TripSummaryCardSchedule = ({
  dateTime,
  originCity,
  destinationCity,
  logoUrl,
  isOpenTicket,
  label,
}) => {
  const { t } = useTranslation('purchase');
  const date = dateTime ? dateTime.format('DD MMM YYYY') : null;
  const time = dateTime ? dateTime.format('LT') : null;

  return (
    <div className="trip-summary">
      <Spacing size="XS" vertical>
        <p className="trip-summary-label">{label}</p>

        <ProvidersGrid logosToShow={logoUrl} />
        <Text size="S" weight="semibold">{`${originCity} - ${destinationCity}`}</Text>
        {isOpenTicket ? (
          <Text size="XS">{t('label.open_ticket')}</Text>
        ) : (
          <Spacing size="S">
            <Text size="S">{date}</Text>
            <Text size="S">{time}</Text>
          </Spacing>
        )}
      </Spacing>
    </div>
  );
};

TripSummaryCardSchedule.propTypes = {
  dateTime: PropTypes.instanceOf(Moment),
  originCity: PropTypes.string.isRequired,
  destinationCity: PropTypes.string.isRequired,
  logoUrl: PropTypes.array.isRequired,
  isOpenTicket: PropTypes.bool,
  label: PropTypes.string,
};

TripSummaryCardSchedule.defaultProps = {
  dateTime: null,
  isOpenTicket: false,
  label: '',
};

export default TripSummaryCardSchedule;
